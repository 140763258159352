<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        Czy na pewno chcesz oznaczyć zadanie jako <template v-if="isTaskCompleted">
          nie
        </template>wykonane?

        <v-textarea
          v-model="comment"
          class="mt-8"
          outlined
          :rows="3"
          auto-grow
          label="Komentarz"
          placeholder="Wpisz komentarz"
        />
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        :loading="isProcessing"
        v-shortkey="{enter: ['enter']}"
        @shortkey.native="updateValue()"
        @click="updateValue()"
      >
        Oznacz jako <template v-if="isTaskCompleted">
          nie
        </template>wykonane
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data: () => ({
    comment: undefined,
  }),
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.tasks.isProcessing
    }),
    isTaskCompleted () {
      return this.item.done
    }
  },
  methods: {
    ...mapActions({
      editTask: 'tasks/editItem',
      getTasks: 'tasks/getItems',
      closeDialog: 'layout/closeDialog',
    }),
    updateValue () {
      const { id, done } = this.item
      this.editTask({
        tableName: 'tasks',
        params: {
          done: !done,
          comment: this.comment,
        },
        id,
        notWebSocketHandled: true
      }).then(() => {
        if (this.$route.name === 'tasks') this.getTasks()
        this.closeDialog()
      })
    }
  }
}
</script>
